import { isObject } from '@/libs/objects'
import dotProp from 'dot-prop'
import type { RuleContext, RuleNameContextual, RuleNameParametrized, RuleNameStandalone } from '@/types/validator'

// nullable is always valid, regardless of given value and existence.
// Added to cover PHP laravel rules. Use other rules to narrow down the case.
export const nullable = () => ''
export const is_empty = (val: any) => {
  return ['undefined', 'null', ''].includes(`${val}`) && !Array.isArray(val) ? 'Value is empty' : ''
}
export const required = (val: any) => (is_empty(val) ? 'Value can not be empty' : '')
export const string = (val: any) => (typeof val !== 'string' ? 'Value must be a string' : '')
export const numeric = (val: any) => (typeof val !== 'number' ? 'Value must be a number' : '')
export const boolean = (val: any) => (typeof val !== 'boolean' ? 'Value must be a boolean' : '')
export const array = (val: any) => (!Array.isArray(val) && !isObject(val) ? 'Value must be an array' : '')
export const email = (val: any) => (!String(val).match(/\S+@\S+\.\S+/) ? 'Invalid email format' : '')
export const not_numbers_only = (val: any) => (/^\d+$/.test(val) ? "Value shouldn't be numeric" : '')
export const empty_spaces = (val: any) => (String(val).match(/\s/g) ? 'No whitespaces allowed' : '')
export const alpha_dash = (val: any) => {
  const errorMessage = 'Value may only contain letters, numbers, dashes and underscores'
  return String(val).match(/[^_\p{L}\p{N}\p{M}-]/gu) ? errorMessage : ''
}
export const lower_alpha_floor = (val: any) => {
  const errorMessage = 'Value may only contain letters and underscores'
  return String(val).match(/[^_\p{L}\p{M}]/gu) ? errorMessage : ''
}

export const domain = (val: any) => {
  const pattern = /^(?:[a-z0-9](?:[a-z0-9-æøå]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/isu
  return !String(val).match(pattern) && val !== 'localhost' ? 'Invalid domain format' : ''
}

export const string_or_num = (val: any) => {
  const errorMessage = 'Value may only contain letters and/or numbers'
  return ['string', 'number'].includes(typeof val) ? '' : errorMessage
}

// Parametrized rules
export const gt = (val: any, length: number) => {
  if (typeof val === 'number') {
    return val <= length ? `Value must be greater than: ${length}` : ''
  }

  return !!val && val.length <= length ? `Value must be at least ${length + 1} characters long` : ''
}

export const lt = (val: any, length: number) => {
  if (typeof val === 'number') {
    return val >= length ? `Value must be less than: ${length}` : ''
  }

  return !!val && val.length <= length ? `Value can be max ${length - 1} characters long` : ''
}

export const min = (val: any, length: number) => {
  if (typeof val === 'number') {
    return val < length ? `Min value is: ${length}` : ''
  }
  return !!val && val.length < length ? `Min length is: ${length}` : ''
}

export const max = (val: any, length: number) => {
  if (typeof val === 'number') {
    return val > length ? `Max value is: ${length}` : ''
  }
  return !!val && val.length > length ? `Max length is: ${length}` : ''
}

export const regex = (val: any, pattern: string | RegExp) => {
  return !String(val).match(new RegExp(pattern)) ? 'Value does not match given pattern' : ''
}

export const in_list = (val: any, values: string) => {
  const list = `${values}`.split(',')
  const tokens = Array.isArray(val) ? val : [val]
  return !tokens.every((item) => list.includes(`${item}`)) ? 'Value(s) does not match with anything on the list' : ''
}

export const unique = (val: any, existing?: string[]) => (existing?.includes(val) ? 'Value already exists' : '')

export const required_with = (value: any, context: RuleContext): string => {
  const { model, currentKey, params: requiredWith } = context
  const otherValue = dotProp.get(model, requiredWith)
  const fulfilled = [!is_empty(value), !is_empty(otherValue)]

  return !fulfilled.every(Boolean) ? `${requiredWith} is required with ${currentKey}` : ''
}

export const same = (value: any, context: RuleContext): string => {
  const { model, currentKey, params: otherKey } = context
  const otherValue = dotProp.get(model, otherKey)

  return value !== otherValue ? `${currentKey} must be the same as ${otherKey}` : ''
}

export const standalone: Record<RuleNameStandalone, (val: any) => string> = {
  required,
  string,
  numeric,
  boolean,
  array,
  email,
  not_numbers_only,
  empty_spaces,
  alpha_dash,
  lower_alpha_floor,
  string_or_num,
  domain
}

export const parametrized: Record<RuleNameParametrized, (val: any, param: any) => string> = {
  gt,
  lt,
  min,
  max,
  regex,
  in: in_list,
  unique
}

export const contextual: Record<RuleNameContextual, (value: any, context: RuleContext) => string> = {
  nullable,
  same,
  required_with
}

export const isRuleStandalone = (val: string): val is RuleNameStandalone => val in standalone
export const isRuleParametrized = (val: string): val is RuleNameParametrized => val in parametrized
export const isRuleContextual = (val: string): val is RuleNameContextual => val in contextual
