import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VTabs,{attrs:{"background-color":"primary","dark":"","center-active":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.languageTabs),function(language){return _c(VTab,{key:language.code,on:{"click":function($event){return _vm.$emit('click', language.code)}}},[_c('div',{staticClass:"d-flex flex-gap-16 align-center"},[_c('span',[_vm._v(" "+_vm._s(language.flag)+" "+_vm._s(language.name)+" ")]),(_vm.tabErrors[("" + (language.code.toLowerCase()))])?_c('div',[_vm._t("error-icon",function(){return [_c(VIcon,{attrs:{"color":"red"}},[_vm._v("error")])]},{"code":language.code})],2):_vm._e()])])}),1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.languageTabs),function(language){return _c(VTabItem,{key:language.code,attrs:{"eager":""}},[_c(VCard,{staticClass:"ma-4 d-flex flex-column flex-gap-16",attrs:{"flat":""}},[_vm._l((_vm.translatableValues),function(translatableValue){return _vm._t(_vm.getSlotName(language.code, translatableValue),null,{"currentTab":_vm.tab,"code":language.code.toLowerCase()})})],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }