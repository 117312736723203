import { computed, ref } from '@vue/composition-api'

const cmsProductImagePlaceholder = ref('')

export const useProductPlaceholder = () => {
  const setProductImagePlaceholder = (val: string) => (cmsProductImagePlaceholder.value = val)

  return {
    productPlaceholder: computed(() => cmsProductImagePlaceholder.value),
    setProductImagePlaceholder
  }
}
